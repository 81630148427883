/* eslint-disable no-unused-expressions */
import logo from "./logo.svg";
import "./App.css";

function App() {

	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
					test--facebook web2app
				</a>
			</header>
		</div>
	);
}

export default App;
